@import "icons-variables";

@font-face {
  font-family: '#{$icons-font-family}';
  src: url('#{$icons-font-path}/#{$icons-font-family}.eot?2s64hy');
  src: url('#{$icons-font-path}/#{$icons-font-family}.eot?2s64hy#iefix') format('embedded-opentype'),
    url('#{$icons-font-path}/#{$icons-font-family}.ttf?2s64hy') format('truetype'),
    url('#{$icons-font-path}/#{$icons-font-family}.woff?2s64hy') format('woff'),
    url('#{$icons-font-path}/#{$icons-font-family}.svg?2s64hy##{$icons-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

i{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icons-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering========= */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic__face_cry {
  &:before {
    content: $ic__face_cry; 
  }
}
.ic__face_happy {
  &:before {
    content: $ic__face_happy; 
  }
}
.ic__face_neutral {
  &:before {
    content: $ic__face_neutral; 
  }
}
.ic__face_sad {
  &:before {
    content: $ic__face_sad; 
  }
}
.ic__face_smile {
  &:before {
    content: $ic__face_smile; 
  }
}
.ic_add {
  &:before {
    content: $ic_add; 
  }
}
.ic_add_circle {
  &:before {
    content: $ic_add_circle; 
  }
}
.ic_arrow_down {
  &:before {
    content: $ic_arrow_down; 
  }
}
.ic_arrow_down_circle {
  &:before {
    content: $ic_arrow_down_circle; 
  }
}
.ic_arrow_dropdown {
  &:before {
    content: $ic_arrow_dropdown; 
  }
}
.ic_arrow_right {
  &:before {
    content: $ic_arrow_right; 
  }
}
.ic_arrow_right_circle {
  &:before {
    content: $ic_arrow_right_circle; 
  }
}
.ic_arrow_up {
  &:before {
    content: $ic_arrow_up; 
  }
}
.ic_arrow_up_circle {
  &:before {
    content: $ic_arrow_up_circle; 
  }
}
.ic_birthday {
  &:before {
    content: $ic_birthday; 
  }
}
.ic_block {
  &:before {
    content: $ic_block; 
  }
}
.ic_blocks_table {
  &:before {
    content: $ic_blocks_table; 
  }
}
.ic_blocks_view {
  &:before {
    content: $ic_blocks_view; 
  }
}
.ic_calendar {
  &:before {
    content: $ic_calendar; 
  }
}
.ic_call {
  &:before {
    content: $ic_call; 
  }
}
.ic_cancel {
  &:before {
    content: $ic_cancel; 
  }
}
.ic_card {
  &:before {
    content: $ic_card; 
  }
}
.ic_check {
  &:before {
    content: $ic_check; 
  }
}
.ic_check_circle {
  &:before {
    content: $ic_check_circle; 
  }
}
.ic_client {
  &:before {
    content: $ic_client; 
  }
}
.ic_clip {
  &:before {
    content: $ic_clip; 
  }
}
.ic_clock {
  &:before {
    content: $ic_clock; 
  }
}
.ic_close {
  &:before {
    content: $ic_close; 
  }
}
.ic_close_circle {
  &:before {
    content: $ic_close_circle; 
  }
}
.ic_colaboration {
  &:before {
    content: $ic_colaboration; 
  }
}
.ic_contract {
  &:before {
    content: $ic_contract; 
  }
}
.ic_copy {
  &:before {
    content: $ic_copy; 
  }
}
.ic_credit_card {
  &:before {
    content: $ic_credit_card; 
  }
}
.ic_delete {
  &:before {
    content: $ic_delete; 
  }
}
.ic_docfile {
  &:before {
    content: $ic_docfile; 
  }
}
.ic_document {
  &:before {
    content: $ic_document; 
  }
}
.ic_dots {
  &:before {
    content: $ic_dots; 
  }
}
.ic_download {
  &:before {
    content: $ic_download; 
  }
}
.ic_edit {
  &:before {
    content: $ic_edit; 
  }
}
.ic_explore {
  &:before {
    content: $ic_explore; 
  }
}
.ic_fav {
  &:before {
    content: $ic_fav; 
  }
}
.ic_file {
  &:before {
    content: $ic_file; 
  }
}
.ic_folder {
  &:before {
    content: $ic_folder; 
  }
}
.ic_home {
  &:before {
    content: $ic_home; 
  }
}
.ic_id {
  &:before {
    content: $ic_id; 
  }
}
.ic_idea {
  &:before {
    content: $ic_idea; 
  }
}
.ic_image {
  &:before {
    content: $ic_image; 
  }
}
.ic_info {
  &:before {
    content: $ic_info; 
  }
}
.ic_link {
  &:before {
    content: $ic_link; 
  }
}
.ic_love {
  &:before {
    content: $ic_love; 
  }
}
.ic_mail {
  &:before {
    content: $ic_mail; 
  }
}
.ic_map {
  &:before {
    content: $ic_map; 
  }
}
.ic_menu {
  &:before {
    content: $ic_menu; 
  }
}
.ic_message {
  &:before {
    content: $ic_message; 
  }
}
.ic_mic {
  &:before {
    content: $ic_mic; 
  }
}
.ic_notification {
  &:before {
    content: $ic_notification; 
  }
}
.ic_out {
  &:before {
    content: $ic_out; 
  }
}
.ic_pdf {
  &:before {
    content: $ic_pdf; 
  }
}
.ic_pic {
  &:before {
    content: $ic_pic; 
  }
}
.ic_print {
  &:before {
    content: $ic_print; 
  }
}
.ic_refresh {
  &:before {
    content: $ic_refresh; 
  }
}
.ic_remove_circle {
  &:before {
    content: $ic_remove_circle; 
  }
}
.ic_search {
  &:before {
    content: $ic_search; 
  }
}
.ic_send {
  &:before {
    content: $ic_send; 
  }
}
.ic_setting {
  &:before {
    content: $ic_setting; 
  }
}
.ic_share {
  &:before {
    content: $ic_share; 
  }
}
.ic_shop {
  &:before {
    content: $ic_shop; 
  }
}
.ic_sound {
  &:before {
    content: $ic_sound; 
  }
}
.ic_spreadsheet {
  &:before {
    content: $ic_spreadsheet; 
  }
}
.ic_store {
  &:before {
    content: $ic_store; 
  }
}
.ic_update_info {
  &:before {
    content: $ic_update_info; 
  }
}
.ic_user {
  &:before {
    content: $ic_user; 
  }
}
.ic_vacation {
  &:before {
    content: $ic_vacation; 
  }
}
.ic_video {
  &:before {
    content: $ic_video; 
  }
}
.ic_videofile {
  &:before {
    content: $ic_videofile; 
  }
}
.ic_view {
  &:before {
    content: $ic_view; 
  }
}
.ic_view_hide {
  &:before {
    content: $ic_view_hide; 
  }
}
.ic_whatsapp {
  &:before {
    content: $ic_whatsapp; 
  }
}