.error{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    .container{
        .number{
            font-size: 300px;
            text-align: center;
        }
        .description{
            font-size: 25px;
            text-align: center;
        }
    }
}