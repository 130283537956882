$icons-font-family: "icohitss" !default;
$icons-font-path: "../fonts" !default;
$ic__face_cry: "\e900";
$ic__face_happy: "\e901";
$ic__face_neutral: "\e902";
$ic__face_sad: "\e903";
$ic__face_smile: "\e904";
$ic_add: "\e905";
$ic_add_circle: "\e906";
$ic_arrow_down: "\e907";
$ic_arrow_down_circle: "\e908";
$ic_arrow_dropdown: "\e909";
$ic_arrow_right: "\e90a";
$ic_arrow_right_circle: "\e90b";
$ic_arrow_up: "\e90c";
$ic_arrow_up_circle: "\e90d";
$ic_birthday: "\e90e";
$ic_block: "\e90f";
$ic_blocks_table: "\e910";
$ic_blocks_view: "\e911";
$ic_calendar: "\e912";
$ic_call: "\e913";
$ic_cancel: "\e914";
$ic_card: "\e915";
$ic_check: "\e916";
$ic_check_circle: "\e917";
$ic_client: "\e918";
$ic_clip: "\e919";
$ic_clock: "\e91a";
$ic_close: "\e91b";
$ic_close_circle: "\e91c";
$ic_colaboration: "\e91d";
$ic_contract: "\e91e";
$ic_copy: "\e91f";
$ic_credit_card: "\e920";
$ic_delete: "\e921";
$ic_docfile: "\e922";
$ic_document: "\e923";
$ic_dots: "\e924";
$ic_download: "\e925";
$ic_edit: "\e926";
$ic_explore: "\e927";
$ic_fav: "\e928";
$ic_file: "\e929";
$ic_folder: "\e92a";
$ic_home: "\e92b";
$ic_id: "\e92c";
$ic_idea: "\e92d";
$ic_image: "\e92e";
$ic_info: "\e92f";
$ic_link: "\e930";
$ic_love: "\e931";
$ic_mail: "\e932";
$ic_map: "\e933";
$ic_menu: "\e934";
$ic_message: "\e935";
$ic_mic: "\e936";
$ic_notification: "\e937";
$ic_out: "\e938";
$ic_pdf: "\e939";
$ic_pic: "\e93a";
$ic_print: "\e93b";
$ic_refresh: "\e93c";
$ic_remove_circle: "\e93d";
$ic_search: "\e93e";
$ic_send: "\e93f";
$ic_setting: "\e940";
$ic_share: "\e941";
$ic_shop: "\e942";
$ic_sound: "\e943";
$ic_spreadsheet: "\e944";
$ic_store: "\e945";
$ic_update_info: "\e946";
$ic_user: "\e947";
$ic_vacation: "\e948";
$ic_video: "\e949";
$ic_videofile: "\e94a";
$ic_view: "\e94b";
$ic_view_hide: "\e94c";
$ic_whatsapp: "\e94d";

